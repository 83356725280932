<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <MainForm
        :form="form"
        :error="error"
        :purpose="purpose"
        :mainSentenceShow="false"
        citizenTitleButton="Pilih Penduduk"
      >
        <template v-slot:main>
          <b-form-group
            id="input-group-transfer_type_id"
            label="Jenis Pindah:"
            label-for="input-transfer_type_id"
          >
            <treeselect
              v-model="form.transfer_type_id"
              :multiple="false"
              placeholder="Pilih Jenis Pindah"
              :options="transfer_types"
            />
            <small class="text-danger">{{ error.transfer_type_id }}</small>
          </b-form-group>

          <b-form-group id="input-group-destination">
            <label for="input-destination">Alamat Tujuan Pindah: </label>
            <b-form-textarea
              id="input-destination"
              v-model="form.destination"
              placeholder="Alamat Tujuan Pindah"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.destination }}</small>
          </b-form-group>

          <b-form-group id="input-group-reason">
            <label for="input-reason">Alasan Pindah: </label>
            <b-form-textarea
              id="input-reason"
              v-model="form.reason"
              placeholder="Alasan Pindah"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.reason }}</small>
          </b-form-group>

          <!-- <b-form-group
              id="input-group-total_member"
              label="Jumlah anggota keluarga yang ikut pindah:"
              label-for="input-total_member"
            >
              <b-form-input
                id="input-total_member"
                v-model="form.total_member"
                placeholder="Jumlah anggota keluarga yang ikut pindah"
              ></b-form-input>
              <small class="text-danger">{{ error.total_member }}</small>
            </b-form-group> -->

          <div class="row">
            <div class="col-sm-12">
              <b-button
                variant="primary"
                class="text-white"
                block
                @click="$bvModal.show('modal-citizen-multiple')"
              >
                Pilih Anggota Keluarga yang Pindah
              </b-button>
            </div>
            <div class="col-sm-12 pt-2">
              <table class="table thead-light">
                <thead class="thead-light">
                  <tr>
                    <th>No</th>
                    <th>NIK</th>
                    <th>Nama Lengkap</th>
                    <th>SHDK</th>
                    <th></th>
                  </tr>
                </thead>
                <tr v-for="(family, i) in familyCollection" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ family.id_card_number }}</td>
                  <td>{{ family.name }}</td>
                  <td>{{ family.shdk_name }}</td>
                  <td>
                    <b-button
                      class="ml-2"
                      variant="danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteCitizen(i)"
                      size="sm"
                    >
                      <i class="fas fa-trash px-0"></i>
                    </b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </MainForm>
      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/transfer-certificate')"
      >
        Batal
      </b-button>
    </b-form>
    <ModalCitizenMultiple
      @checkCitizen="checkCitizen"
      @unCheckCitizen="unCheckCitizen"
      :familyCollection="familyCollection"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalCitizenMultiple from "@/view/components/general/ModalCitizenMultiple.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    MainForm,
    ModalCitizenMultiple,
  },

  data() {
    return {
      // Error
      error: {
        transfer_type_id: "",
        reason: "",
        address: "",
        destination: "",
        total_member: "",
        // 
        name: "",
        id_card_number: "",
        gender: "",
        birt_place: "",
        birt_date: "",
        title: "",
        number: "",
        hamlet_id: "",
        purpose: "",
      },
      modal2: false,
      transfer_types: [],
      selected_family_form: {
        citizen_id: "",
        name: "",
        id_card_number: "",
        shdk_id: "",
        shdk_name: "",
      },
      citizenPurpose: "add",
      familyCollection: [],
      familyCollectionDeleted: [],
    };
  },

  methods: {
    async getTransferTypeOption() {
      let response = await module.setTreeSelect("transfer-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.transfer_types = response.data;
        this.transfer_types.unshift({
          label: "Pilih Jenis Pindah",
          id: "",
          isDisabled: true,
        });
      }
    },

    checkCitizen(value) {
      this.citizenPurpose = "add";
      this.selected_family_form.citizen_id = value.id;
      this.selected_family_form.name = value.name;
      this.selected_family_form.id_card_number = value.id_card_number;
      this.selected_family_form.shdk_id = value.status_in_family;
      this.selected_family_form.shdk_name = value.status_in_family_display;

      let clone = { ...this.selected_family_form };
      this.familyCollection.push(clone);
      console.log("familyCollection", this.familyCollection);
    },
    unCheckCitizen(value) {
      for (let a = 0; a < this.familyCollection.length; a++) {
        if (this.familyCollection[a].citizen_id == value.id) {
          this.familyCollection.splice(a, 1);
        }
      }
    },
    deleteCitizen(index) {
      this.familyCollectionDeleted.push(this.familyCollection[index].id);
      //   console.log("familyCollectionDeleted", this.familyCollectionDeleted);
      this.familyCollection.splice(index, 1);
    },

    async formOnsubmit() {
      this.form.total_member = this.familyCollection.length;
      this.form.selected_family = JSON.stringify(this.familyCollection);
      if (this.familyCollectionDeleted.length > 0) {
        this.form.familyCollectionDeleted = JSON.stringify(
          this.familyCollectionDeleted
        );
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/transfer-certificate");
      }
    },
  },
  mounted() {
    this.getTransferTypeOption();
  },
  watch: {
    form: function (newVal, oldVal) {
      if(this.purpose == "edit"){
          this.familyCollection = this.form.family_member_transfer;
      }
    },
  },
};
</script>

<style>
</style>