<template>
  <div>
    <b-modal
      id="modal-citizen-multiple"
      size="xl"
      title="Pilih Penduduk"
      hide-footer
    >
      <Table v-if="isAuth" purpose="modal-citizen-multiple" @unCheckCitizen="unCheckCitizen" @checkCitizen="checkCitizen" :familyCollection="familyCollection"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/citizen/Table.vue";

export default {
  props: {
      familyCollection: Array,
      isAuth:{
        type: Boolean,
        default: true
      }
  },

  components: {
    Table,
  },

  methods: {
    checkCitizen(value) {
      this.$emit("checkCitizen", value);
    },
    unCheckCitizen(value){
        console.log('uncek modal ', value)
      this.$emit("unCheckCitizen", value)
    }
  },
};
</script>

<style>
</style>
